import React from "react";
import NewBanner from "./NewBanner";


const MainPage = (props) =>{
    return (
        <>
            <NewBanner/>
        </>
    )
            
}

export default MainPage;
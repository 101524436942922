import { React, useEffect, useRef, useState } from "react";
import { Row, Col, Container, Image, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from "jquery";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
// import { TweenMax } from "gsap/gsap-core";
import Countdown from "react-countdown";
import backgroundGif from '../assets/images/background.gif';
import Sun from '../assets/images/sun.png';
import Stairs from '../assets/images/stairs.png';
import Tile22 from '../assets/images/tile22.png';
import Tile21 from '../assets/images/tile21.png';
import Nftfram from '../assets/images/nft-fram.png';
import Tile12 from '../assets/images/tile12.png';
import Tile11 from '../assets/images/tile11.png';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
// setTimeout(function () {
// 	window.scrollTo(0, 0);
// 	$(".loader").fadeOut("fast");
// }, 3500);
// setTimeout(function () {
// 	$(".bear1").css("bottom", "-10%");
// }, 4000);

// $(".faqs-section").on("scroll", function () {
// 	if ($(this).scrollTop() == "0") {
// 		alert("End of DIV is reached!");
// 	}
// });
const Banner = (props) => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const Completionist = () => <span className="time-wrapper">Time Completed</span>;
	const [LeftMove, SetLeftMove] = useState(false);
	const [RightMove, SetRightMove] = useState(false);
	const [LeftDrop, SetLeftDrop] = useState(false);
	const [RightDrop, SetRightDrop] = useState(false);


	// Renderer time clock callback with condition
	const renderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) {
			// Render a completed state
			return <Completionist />;
		} else {
			// Render a countdown
			return <span className="time-wrapper">{hours}:{minutes}:{seconds}</span>;
		}
	};

	return (
		<div className="game-banner-wraper">
			<div className="bridge-background">
				<Image src={backgroundGif} fluid />
			</div>
			<div className="sun-image">
				<Image className="rotate" src={Sun} fluid />
			</div>
			<div className="stairs-image">
				<Image src={Stairs} fluid />
			</div>
			<div className="tile-row tile-row7-image">
				<div className="slow-up-down">
					<div className="d-flex justify-content-around">
						<div className="tile left-tile">
							<Image src={Tile22} fluid />
						</div>
						<div className="tile right-tile">
							<Image src={Tile21} fluid />
						</div>
					</div>
					<div className="nft-fram-wrapper right-nft-fram-wrapper d-flex align-items-end">
						<button className="more-button" onClick={handleShow}>
							More
						</button>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
						</div>
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
					</div>
					<div className="nft-fram-wrapper left-nft-fram-wrapper d-flex align-items-end">
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
						</div>
						<button className="more-button" onClick={handleShow}>
							More
						</button>
					</div>
				</div>
			</div>
			<div className="tile-row tile-row6-image">
				<div className="slow-up-down">
					<div className="d-flex justify-content-around">
						<div className="tile left-tile">
							<Image src={Tile22} fluid />
						</div>
						<div className="tile right-tile">
							<Image src={Tile21} fluid />
						</div>
					</div>
					<div className="nft-fram-wrapper left-nft-fram-wrapper d-flex align-items-end">
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
						</div>
						<button className="more-button" onClick={handleShow}>
							More
						</button>
					</div>
					<div className="nft-fram-wrapper right-nft-fram-wrapper d-flex align-items-end">
						<button className="more-button" onClick={handleShow}>
							More
						</button>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
						</div>
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
					</div>
				</div>
			</div>
			<div className="tile-row tile-row5-image">
				<div className="up-down">
					<div className="d-flex justify-content-around">
						<div className="tile left-tile">
							<Image src={Tile12} fluid />
						</div>
						<div className="tile right-tile">
							<Image src={Tile11} fluid />
						</div>
					</div>
					<div className="nft-fram-wrapper left-nft-fram-wrapper d-flex align-items-end">
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
						</div>
						<button className="more-button" onClick={handleShow}>
							More
						</button>
					</div>
					<div className="nft-fram-wrapper right-nft-fram-wrapper d-flex align-items-end">
						<button className="more-button" onClick={handleShow}>
							More
						</button>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
						</div>
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
					</div>
				</div>
			</div>
			<div className="tile-row tile-row4-image">
				<div className="slow-up-down">
					<div className="d-flex justify-content-around">
						<div className="tile left-tile">
							<Image src={Tile22} fluid />
						</div>
						<div className="tile right-tile">
							<Image src={Tile21} fluid />
						</div>
					</div>
					<div className="nft-fram-wrapper left-nft-fram-wrapper d-flex align-items-end">
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
						</div>
						<button className="more-button" onClick={handleShow}>
							More
						</button>
					</div>
					<div className="nft-fram-wrapper right-nft-fram-wrapper d-flex align-items-end">
						<button className="more-button" onClick={handleShow}>
							More
						</button>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
						</div>
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
					</div>
				</div>
			</div>
			<div className="tile-row tile-row3-image">
				<div className="up-down">
					<div className="d-flex justify-content-around">
						<div className="tile left-tile">
							<Image src={Tile12} fluid />
						</div>
						<div className="tile right-tile">
							<Image src={Tile11} fluid />
						</div>
					</div>
					<div className="nft-fram-wrapper left-nft-fram-wrapper d-flex align-items-end">
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
						</div>
						<button className="more-button" onClick={handleShow}>
							More
						</button>
					</div>
					<div className="nft-fram-wrapper right-nft-fram-wrapper d-flex align-items-end">
						<button className="more-button" onClick={handleShow}>
							More
						</button>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<div className="user-nft-img me-2">
									<Image src={Nftfram} fluid />
								</div>
								<p className="nft-name">Name XYZ</p>
							</div>
						</div>
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
					</div>
				</div>
			</div>
			<div className="tile-row tile-row2-image">
				<div className="slow-up-down">
					<div className="d-flex justify-content-around">
						<div className={`tile left-tile ${LeftDrop ? "drop-left-tile" : ""}`}>
							<Image src={Tile21} fluid />
						</div>
						<div className={`tile right-tile ${RightDrop ? "drop-right-tile" : ""}`}>
							<Image src={Tile22} fluid />
						</div>
					</div>
				</div>
			</div>


			<div className="tile-row tile-row1-image">
				<div className="up-down">
					<div className="d-flex justify-content-around">
						<div className="tile left-tile">
							<Image src={Tile11} fluid />
						</div>
					</div>
					<div className={`nft-fram-wrapper left-nft-fram-wrapper d-flex align-items-end 
                    ${LeftMove ? `left-move ${LeftDrop ? "drop-left-nft" : ""}` : ""} 
                    ${RightMove ? `right-move ${RightDrop ? "drop-right-nft" : ""}` : ""}`}>
						<div className="nft-fram">
							<Image src={Nftfram} fluid />
						</div>
						<div className="related-nft">
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
							<div className="nft-name-img d-flex align-items-center">
								<p className="nft-name">Name XYZ</p>
								<div className="user-nft-img ms-2">
									<Image src={Nftfram} fluid />
								</div>
							</div>
						</div>
						<button className="more-button" onClick={handleShow}>
							More
						</button>
					</div>
				</div>
			</div>

			<div className="move-btns">
				<div className="mb-4">
					{/* <button className="left-btn me-4" onClick={() => SetLeftMove(!LeftMove)}>left</button>
					<button className="right-btn" onClick={() => SetRightMove(!RightMove)}>Right</button> */}
				</div>
				<button className="left-btn me-4" onClick={() => SetLeftDrop(!LeftDrop)}>Drop left</button>
				<button className="right-btn" onClick={() => SetRightDrop(!RightDrop)}>Drop Right</button>
			</div>
			{!LeftMove && !RightMove ?
				<div className="time-modal">
					<h2 className="text-center mt-3">Select Tile For Jump</h2>
					<div className="arrow-wrapper d-flex align-items-center justify-content-between">
						<FontAwesomeIcon onClick={() => SetLeftMove(!LeftMove)} icon={faArrowAltCircleLeft} />
						<Countdown
							date={Date.now() + 50000}
							renderer={renderer}
						/>
						<FontAwesomeIcon onClick={() => SetRightMove(!RightMove)} icon={faArrowAltCircleRight} />
					</div>
				</div>
				: ""
			}
			<Modal className="nft-modal" show={show} onHide={handleClose}>
				<Modal.Header className="justify-content-center">
					<Modal.Title>Players List</Modal.Title>
				</Modal.Header>
				<Modal.Body closeButton>
					<div className="nft-content-wrapper d-flex align-items-center">
						<div className="nft-img">
							<Image src={Nftfram} fluid />
						</div>
						<div className="nft-content">
							<h2>Name XYZ</h2>
						</div>
					</div>
					<div className="nft-content-wrapper d-flex align-items-center">
						<div className="nft-img">
							<Image src={Nftfram} fluid />
						</div>
						<div className="nft-content">
							<h2>Name XYZ</h2>
						</div>
					</div>

					<div className="nft-content-wrapper d-flex align-items-center">
						<div className="nft-img">
							<Image src={Nftfram} fluid />
						</div>
						<div className="nft-content">
							<h2>Name XYZ</h2>
						</div>
					</div><div className="nft-content-wrapper d-flex align-items-center">
						<div className="nft-img">
							<Image src={Nftfram} fluid />
						</div>
						<div className="nft-content">
							<h2>Name XYZ</h2>
						</div>
					</div>
				</Modal.Body>
				{/* <Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
					<Button variant="primary" onClick={handleClose}>
							Save Changes
						</Button>
				</Modal.Footer> */}
			</Modal>
		</div>
	)
}

export default Banner;
